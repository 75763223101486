import clsx from "clsx";
import React from "react";
import { H2, Paragraph } from "~/components/ui/typography";
import type { Cta } from "~/types";
import { CtaButtonGroup } from "~/components/ui/button";
import { BlockContent } from "~/components/block-content";
import type { SimplePortableText } from "~/types/sanity-schema";

export interface Props {
	headerPosition?: "default" | "left" | "right";
	headerSize?: "narrow" | "wide";
	align?: "center" | "left";
	children: [
		React.ReactElement<HeaderProps>,
		React.ReactElement<ContentProps>,
		React.ReactElement<FooterProps>?
	];
}

const SectionWithHeaderLayutContext = React.createContext<{
	headerPosition: Props["headerPosition"];
	align: "center" | "left";
} | null>(null);

export function useCardListLayoutContext() {
	const context = React.useContext(SectionWithHeaderLayutContext);

	if (context === null) {
		throw new Error(
			"useCardListLayoutContext must be used within a CardListLayoutContext Provider"
		);
	}

	return context;
}

export function CardListLayout({
	headerPosition = "default",
	align = "center",
	children,
}: Props) {
	return (
		<div
			className={clsx(
				"flex flex-col",
				{ "gap-8": headerPosition === "default" },
				{ "gap-9": headerPosition != "default" },
				{ "lg:flex-row": headerPosition === "left" },
				{ "lg:flex-row-reverse": headerPosition === "right" }
			)}
		>
			<SectionWithHeaderLayutContext.Provider
				value={{ headerPosition, align }}
			>
				{children}
			</SectionWithHeaderLayutContext.Provider>
		</div>
	);
}

// * ================== Header =====================

interface HeaderProps {
	tagline?: string;
	subtitle?: SimplePortableText;
	title?: string;
	primaryCTA?: Cta;
	secondaryCTA?: Cta;
	tertiaryCTA?: Cta;
	isNarrow?: boolean;
}

function Header({
	tagline,
	subtitle,
	title,
	primaryCTA,
	secondaryCTA,
	tertiaryCTA,
	isNarrow = false,
}: HeaderProps) {
	const { headerPosition, align } = useCardListLayoutContext();

	const alignStyles = {
		left: "text-left",
		center: "mx-auto max-w-content md:text-center",
	};

	const headerStyles =
		headerPosition === "default" ? alignStyles[align] : "text-left";

	if (!title && !subtitle && !tagline) {
		return null;
	}

	return (
		<div
			className={clsx(
				headerStyles,
				{
					"basis-4/12": isNarrow,
				},
				{
					"basis-6/12": !isNarrow,
				}
			)}
		>
			{tagline ? (
				<Paragraph size="overline" className="mb-5" color="tagline">
					{tagline}
				</Paragraph>
			) : null}
			{title ? <H2 className="mb-5">{title}</H2> : null}
			{subtitle ? (
				typeof subtitle === "string" ? (
					<Paragraph>{subtitle}</Paragraph>
				) : (
					<BlockContent value={subtitle} />
				)
			) : null}
			{primaryCTA?.title || secondaryCTA?.title || tertiaryCTA?.title ? (
				<div
					className={clsx("flex flex-wrap gap-6 pt-4", {
						"justify-center":
							align === "center" && headerPosition === "default",
					})}
				>
					<CtaButtonGroup
						primaryCTA={primaryCTA}
						secondaryCTA={secondaryCTA}
						tertiaryCTA={tertiaryCTA}
					/>
				</div>
			) : null}
		</div>
	);
}

// * ================== Content =====================

interface ContentProps {
	children: React.ReactNode;
}

function Content({ children }: ContentProps) {
	return <div className="flex-1">{children}</div>;
}

// * ================== Footer =====================

interface FooterProps {
	primaryCTA?: Cta;
	secondaryCTA?: Cta;
	tertiaryCTA?: Cta;
}

function Footer({ primaryCTA, secondaryCTA, tertiaryCTA }: FooterProps) {
	const { align } = useCardListLayoutContext();

	if (!primaryCTA?.title && !secondaryCTA?.title && !tertiaryCTA?.title) {
		return null;
	}
	return (
		<div
			className={clsx("mt-4 flex flex-wrap gap-6", {
				"justify-center": align === "center",
			})}
		>
			<CtaButtonGroup
				primaryCTA={primaryCTA}
				secondaryCTA={secondaryCTA}
				tertiaryCTA={tertiaryCTA}
			/>
		</div>
	);
}

CardListLayout.Header = Header;
CardListLayout.Content = Content;
CardListLayout.Footer = Footer;
